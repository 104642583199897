import React, {useState, useEffect } from 'react';


const Screening = () => {
  return (
    <div style={{width:'100%',height:'100%'}}>

    </div>
  )
}

export default Screening