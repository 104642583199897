import React from 'react'

const Mcare = () => {
  return (
    <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',backgroundColor:'white'}}>
        <p>Scan to download MCare App</p>
        <div className='QR'/>
    </div>
  )
}

export default Mcare